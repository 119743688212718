.carousel-container {
    overflow: hidden;
    position: relative;
    height: 300px; /* Set a specific height */
    width: 100%;
}

.carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
}

.carousel-card:hover {
    transform: scale(0.9);
}

.carousel-card > img {
    max-height: 70%;
    min-width: auto;
    opacity: 0.75;
}

.carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: start; /* Changed from center to start */
    align-items: center;
    gap: 10px;
    width: 200%;
    height: 100%;
    animation: slide 50s linear infinite;
}

.carousel-container h1 {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%); /* Ensure the centering is accurate */
    color: #002F56;
    font-size: 72px;
    z-index: 2; /* Make sure the text is above the images */
    background-color: rgba(255, 255, 255, 0.6); /* Semi-transparent black background */
    padding: 10px 20px; /* Add some padding around the text */
    border-radius: 10px; /* Optional: rounded corners */
}


@keyframes slide {
    from { left: 0; }
    to { left: -202%; }
}

