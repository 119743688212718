
/* video stuff */
.about-container-video {
    position: relative;
    text-align: center;
    align-items: center;
    display: flex; /* Use flexbox to help with centering */
    justify-content: center; /* Center horizontally */
    height: auto; /* Adjust height as necessary */
}

.about-container-video video {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    position: relative;
    z-index: 0;
    opacity: 0.5; /* Lower the opacity to allow text to stand out */
}

.overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); /* Center the overlay */
    z-index: 1;
    color: white; /* Ensure the text color contrasts well with the video */
    color: #002f56;
}

#top_text {
    font-size: 7vw;
    align-self: center;
}


/* text boxes stuff */

.about-img-text-container {
    overflow: hidden;
    position: relative;
    height: auto; /* Set a specific height */
    width: 100%;
    display: flex; /* Using flexbox to align children side by side */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    padding: 20px 0;
    background-color: rgba(0, 47, 86, 0.25);
    display: flex;
    flex-direction: row; 
    flex-grow: 1;
}

.about-img-text-container.Dark {
    background-color: rgba(0, 47, 86, 0.3);
}

.about-img-text-container.Medium {
    background-color: rgba(0, 47, 86, 0.125);
}

.about-img-text-container.Light {
    background-color: rgba(0, 47, 86, 0.075);
}

.about-img-text-container img {
    max-height: 250px;
    padding: 0 3vw;
    margin: 0 auto;
}

.about-img-text-container h1 {
    color: #333;
    flex-grow: 1;
    text-wrap: pretty;
}

.about-img-text-container p {
    color: #333;
    font-size: 1.2em;
    text-wrap: pretty;
    margin: 0 3vw;
}

.about-link {
    text-decoration: underline;
    color: #333;
    font-size: 1em;
}

.about-link:hover {
    cursor: pointer;
}

/* wide images */
.image-wide-container {
    position: relative;
    display: block; 
    width: 100%; 
}

.wide-image {
    width: 100%;
    opacity: 0.5;
    display: block;
    height: auto; 
}

.centered-text {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    font-size: 2vw; 
    color: #002f56;
    text-align: center; 
    width: 100%; 
}

.center-text-no-wide {
    font-size: 2vw; 
    color: #002f56;
    text-align: center; 
}

.rc-table {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 2vh;
    width: 100%; /* Ensure the div takes the full parent width */
}

.rc-table table {
    border-collapse: collapse; /* Ensures the table borders are collapsed into a single border */
    width: auto; /* Allows the table to grow or shrink based on content */
    max-width: 100%; /* Prevents the table from becoming too wide */
    margin: auto; /* Ensures it stays centered */
}

.rc-table table td {
    vertical-align: top; /* Aligns content at the top of the cell */
    padding: 0 20px; /* Adjust padding as necessary */
}

.rc-table ul {
    list-style-type: none; /* Removes the default list style */
    padding: 0;
    margin: 0;
}

.rc-table.Inside ul {
    list-style-type: square; /* Removes the default list style */
}

.rc-table li {
    transition: transform 0.3s;
}
.rc-table li:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.rc-table h3, .rc-table h2 {
    margin: 0;
    margin-bottom: 10px; /* Space below the header */
}
