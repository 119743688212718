.intro-container {
    overflow: hidden;
    position: relative;
    height: 250px; /* Set a specific height */
    width: 100%;
    background-color: #bcd7e8;
    display: flex; /* Using flexbox to align children side by side */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    padding: 20px 0;
    background-color: rgba(0, 47, 86, 0.25);
}

.intro-text-container {
    display: flex;
    flex-direction: column; 
    flex-grow: 1;
}

.intro-container img {
    min-height: 80%;
    max-height: 100%;
    width: auto;
    margin-right: 20px;
    margin-left: 8vw;
}

.intro-text-container h1 {
    color: #333;
    flex-grow: 1;
    text-wrap: pretty;
}

.intro-text-container a {
    color: #333;
    font-size: 1.2em;
    text-wrap: pretty;
    margin-right: 3vw;
}


/* Responsive adjustments */
/* Targeting phone screens */

@media (max-width: 800px) {
    .intro-container {
        flex-direction: column;
        height: auto;
        padding: 10px;
    }

    .intro-container img {
        width: 35%;
        margin-right: 0;
        margin-left: 0;
        align-self: center; 
    }

    .intro-text-container {
        align-items: center;
        text-align: center;
    }

    .intro-text-container h1, .intro-text-container a {
        font-size: 1em;
    }
}


